
import { useRoutes } from 'react-router-dom';
import Login from './pages/auth/Login';
import Home from './pages/home/Home';
import MasterLayout from './pages/admin/layout/MasterLayout';
import Dashboard from './pages/admin/dashboard/Dashboard';
import Types from './pages/admin/types/Types';
import Publications from './pages/admin/publications/Publications';
import AddPublication from './pages/admin/publications/addPublication';
import Region from './pages/admin/region/Region';
import CreateAccount from './pages/auth/CreateAccount';
import ViewPublication from './pages/admin/publications/ViewPublication';
import EditPublication from './pages/admin/publications/EditPublication';
import Administrators from './pages/admin/users/Administrators';
import Users from './pages/admin/users/Users';
import UploadPR from './pages/admin/UploadPR';
import Forgot, { NewPassword } from "./pages/auth/Forgot";


function App() {
  const elementss = useRoutes([
    {
      path: '/',
      element: <Login/>
    },
    {
      path: '/login',
      element: <Login/>
    },
    {
      path: '/create-account',
      element: <CreateAccount/>
    },
    {
      path: '/forgot-password',
      element: <Forgot/>
    },
    {
      path: '/new-password',
      element: <NewPassword/>
    },
    {
      path: '/home',
      element: <Home/>
    },
    {
      path: '/admin',
      element: <MasterLayout/>,
      children:[
        {
          index: true,
          element: <Dashboard/>
        },
        {
          path:'dashboard',
          element: <Dashboard/>
        },
        {
          path:'types',
          element: <Types/>
        },
        {
          path:'publications',
          element: <Publications/>
        },
        {
          path:'add-publication',
          element: <AddPublication/>
        },
        {
          path:'region',
          element: <Region/>
        },
        {
          path:'view-publication/:id',
          element: <ViewPublication/>
        },
        {
          path:'edit-publication/:id',
          element: <EditPublication/>
        },
        {
          path:'administrators',
          element:<Administrators/>
        },
        {
          path:'upload-pr',
          element:<UploadPR/>
        },
        {
          path:'users',
          element:<Users/>
        }
      ]
    },
    {
      path: '*',
      element: <h1>404</h1>
    }
  ]);
  return elementss;
}

export default App;
