import React, { useState, useEffect } from 'react'
import "./home.scss";
import { useNavigate } from 'react-router-dom';

import { Slider } from '@mui/material';
import { BiHelpCircle } from 'react-icons/bi';
import { AiOutlinePicture } from 'react-icons/ai'
import Cookies from 'js-cookie';
import { BASE_URL, HEADER, IMAGE_URL } from '../../Config';
import axios from 'axios';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import CurrencyFormatter from '../../components/CurrencyFormatter';
import AutoHttpsLink from '../../components/AutoHttpsLink';
const Home = () => {
    const [tabActive, setTabActive] = useState('all');
    const navigate = useNavigate();
    const [User, setUser] = useState(false);
    const token = Cookies.get('token');



    useEffect(() => {
        const user = Cookies.get('user');
        // console.log(token);
        if (token) {
            if (user == 2) {
                axios.get(BASE_URL + "/profile", {
                    headers: {
                        'Authorization': `Bearer ${token}`, // Include the Bearer token in the Authorization header
                        'Content-Type': 'application/json', // Set the content type as needed
                    },
                }).then(function (response) {
                    console.log(response);
                    if (response.data.status) {
                        setUser(true);
                        return;


                    }
                    Cookies.remove("token");
                    Cookies.remove("user");
                    navigate("/");

                }).catch(error => {
                    if (error.response) {
                        // console.log(error.response.data.message);
                        // toast.error(error.response.data.message);
                        Cookies.remove("token");
                        Cookies.remove("user");

                        navigate("/");

                    } else if (error.request) {
                        console.log('Network error. Please check your connection.');
                        // toast.error("Network error. Please check your connection.");

                    } else {
                        console.log('An error occurred while fetching data.');
                        // toast.error("An error occurred while processing the request");
                        Cookies.remove("token");
                        Cookies.remove("user");
                        navigate("/");

                    }

                });
            } else if (user == 1) {
                navigate("/admin");
            }

        } else {
            Cookies.remove("token");
            Cookies.remove("user");
            navigate("/");

        }


    }, [])

    function changeTabOption(tabActive) {
        switch (tabActive) {
            case 'all':
                setTabActive('all');
                break;
            case 'group':
                setTabActive('group');
                break;
            case 'panel':
                setTabActive('panel');
                break;
            case 'other':
                setTabActive('other');
                break;
            default:
                setTabActive('all');
        }

        console.log(tabActive);
    }

    function logout() {
        try {
            axios.post(BASE_URL + '/logout', {}, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Include the Bearer token in the Authorization header
                    'Content-Type': 'application/json', // Set the content type as needed
                },
            }).then((res) => {
                Cookies.remove("token");
                Cookies.remove("user");
                navigate("/");
            })
        } catch (error) {

        }
    }


    const handleDownload = async () => {
        try {
            axios.get(BASE_URL + '/download_pr', { headers: HEADER }).then((res) => {
                console.log(res.data);
                const filename = res.data.file_name; // Replace with the actual filename
                const downloadLink = `${IMAGE_URL + '/' + filename}`; // Replace with the correct route URL

                // Create a hidden anchor element and trigger a click event
                const anchor = document.createElement('a');
                anchor.href = downloadLink;
                anchor.download = "filename.docx";
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
            })

            // }
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };
    return (



        <div className="container-fluid w-100">
            <header>
                <nav className='  w-full shadow-md shadow-black/5'>
                    <div className="logo flex align-items-center">
                        <img src={require("../../assets/images/logo.png")} alt="" />
                        <h3>Verified Network</h3>
                    </div>
                    <ul>

                        <li><span onClick={logout}>Logout</span></li>
                    </ul>
                </nav>
            </header>
            <section>
                <div className="">
                    <div className="top-gh">
                        <div className="le">
                            <h3 className='text-white fw-bolder'>PRICING SHEET <span id="ds">(Verified Network)</span></h3>
                            <p className='text-white fw-light'>For payment methods we accept: Wire Transfer, Usdt, Zelle, Payoneer & Credit Card 4% fee.</p>
                        </div>
                        <div className="li">
                            <button onClick={handleDownload} id='bu'>Download PR Questionaire</button>
                        </div>
                    </div>

                    <div className="custom-tab">
                        <ul>
                            <li onClick={() => changeTabOption('all')} className={tabActive === 'all' ? 'activate' : 'non-activate'} ><span>All Publications</span></li>
                            <li onClick={() => changeTabOption('group')} className={tabActive === 'group' ? 'activate' : 'non-activate'} ><span>Groups</span></li>
                            {/* <li onClick={() => changeTabOption('panel')} className={tabActive === 'panel' ? 'activate' : 'non-activate'} ><span>Panel</span></li> */}
                            <li onClick={() => changeTabOption('other')} className={tabActive === 'other' ? 'activate' : 'non-activate'} ><span>Others</span></li>
                        </ul>
                    </div>
                    <div className="custom-tab-content py-3">
                        {tabActive === 'all' ? (<AllPublish />) : tabActive === 'group' ? <Group /> : tabActive === 'panel' ? <Info /> : tabActive === 'other' ? <Other /> : null}
                    </div>
                </div>
            </section>
        </div>




    )
}

export default Home

const AllPublish = () => {
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(20000);
    const [slideValue, setSlideValue] = useState([minValue, maxValue]);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [second, setSecond] = useState([]);
    const [sortOrder, setSortOrder] = useState('p_asc');
    const [sortRegion, setSortRegion] = useState('');
    const [regionData, setRegionData] = useState([]);
    const [Genres, setGenres] = useState([]);
    const [typeData, setTypeData] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [selectedSpons, setSelectedSpons] = useState('');
    const [selectedDo, setSelectedDo] = useState('');
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedIndexed, setSelectedIndexed] = useState('');
    const [selectedGenre, setSelectedGenre] = useState('');
    const navigate = useNavigate();


    function getPublication() {
        setIsLoading(true);
        axios.get(BASE_URL + '/getAllPublication', { headers: HEADER }).then((res) => {
            // console.log(res.data);
            setData(res.data);
            setIsLoading(false);
            setSecond(res.data);
        }).catch((err) => {
            // console.log(err);
        });
    }

    function getRegion() {
        axios.get(BASE_URL + '/regions', { headers: HEADER }).then((res) => {
            // console.log(res.data);
            setRegionData(res.data);
        })
    }

    function getGenres() {
        axios.get(BASE_URL + '/genres', { headers: HEADER }).then((res) => {
            // console.log(res.data);
            // setRegionData(res.data);
            setGenres(res.data)
        })
    }
    function getType() {
        axios.get(BASE_URL + '/types', { headers: HEADER }).then((res) => {
            // console.log("types", res.data);
            // setRegionData(res.data);
            setTypeData(res.data.data);
        })
    }


    function Reset() {
        //  navigate("/home");
        window.location.reload();
    }




    let Sponsored = [
        {
            id: 1,
            name: 'Yes',
        },
        {
            id: 2,
            name: 'No',
        },
        {
            id: 3,
            name: 'Discrete',
        }
    ]

    let Follow = [
        {
            id: 1,
            name: 'Yes',
        },
        {
            id: 2,
            name: 'No',
        },
        {
            id: 3,
            name: 'Maybe',
        }
    ]

    let Images = [
        {
            id: 1,
            name: 'Yes',
        },
        {
            id: 2,
            name: 'Maybe',
        }
    ]
    let Indexed = [
        {
            id: 1,
            name: 'Yes',
        },
        {
            id: 2,
            name: 'Maybe',
        }
    ]
    const handleChange = (event, newValue) => {
        setSlideValue(newValue);

        console.log(newValue[0]);


        setTimeout(() => {
            updateSliderValues(newValue[0], newValue[1]);

        }, 2000)

    };

    const updateSliderValues = (min, max) => {
        axios.post(BASE_URL + "/getByPublicationPrice", { min_price: min, max_price: max }, { headers: HEADER }).then((res) => {
            console.log("Min", res.data)
            setData(res.data);
        }).catch(error => {
            setData(second);
        })

    };

    function loopData(loopp) {
        const fg = JSON.parse(loopp);
        return fg;

    }

    function handleSearch(event) {

        const query = event.target.value;
        // const userInput = event.target.value;
        setSearch(query);

    }

    const handleSortChange = (event) => {

        const newSortOrder = event.target.value;
        console.log(newSortOrder);
        setSortOrder(newSortOrder);

        // Sort the data based on the selected order
        const sortedData = [...data].sort((a, b) => {
            const priceA = a.price || 0;
            const priceB = b.price || 0;

            if (newSortOrder === 'p_asc') {
                return priceA - priceB;
            } else if (newSortOrder === 'p_desc') {
                return priceB - priceA;
            } else if (newSortOrder === 'd_asc') {
                return a.da - b.da;
            } else {
                return b.da - a.da;
            }
        });

        setData(sortedData);
    };

    const handleRegionChange = (event) => {
        // setData(second);

        const reg = event.target.value;
        setSortRegion(reg)
        if (reg === "") {
            setData(second);
        } else {
            const d = second.filter((item) => {
                return item.region_title.includes(reg);
            });
            setData(d);



        }
    }

    const chooseGenreOption = (event) => {
        const f = event
        // setSelectedGenre("");

        // // setData(second);

        if (selectedGenre == f) {
            setSelectedGenre("");
            setData(second);
        } else {
            console.log(f);
            setSelectedGenre(f);

            const filteredData = second.filter((item) => {
                // Parse the genre_id JSON string to an array of objects
                const genreArray = JSON.parse(item.genre_id);

                // Check if any of the objects in the genreArray match the filter item
                return genreArray.some((genre) => genre.id === f);
            });

            // console.log(filteredData);
            setData(filteredData);
        }

    }
    const changeTypeOption = (ite) => {
        // console.log("typess", ite);
        if (selectedType === ite) {
            setSelectedType('');
            setData(second);
        } else {
            setSelectedType(ite);
            const filterResult = second.filter((item) => {
                return item.type_title.includes(ite);
                // item.type_title.includes(ite);
            })
            setData(filterResult);
        }
    }

    const changeDoOption = (ite) => {
        // console.log("typess", ite);
        if (selectedDo === ite) {
            setSelectedDo('');
            setData(second);
        } else {
            setSelectedDo(ite);
            const filterResult = second.filter((item) => {
                return item.do_follow.toLowerCase().includes(ite.toLowerCase());
                // item.type_title.includes(ite);
            })
            setData(filterResult);
        }
    }
    const changeSponsOption = (ite) => {
        // console.log("typess", ite);
        if (selectedSpons === ite) {
            setSelectedSpons('');
            setData(second);
        } else {
            setSelectedSpons(ite);
            const filterResult = second.filter((item) => {
                return item.sponsored.toLowerCase().includes(ite.toLowerCase());
                // item.type_title.includes(ite);
            })
            setData(filterResult);
        }
    }
    const changeImagesOption = (ite) => {
        // console.log("typess", ite);
        if (selectedImage === ite) {
            setSelectedImage('');
            setData(second);
        } else {
            setSelectedImage(ite);
            const filterResult = second.filter((item) => {
                return item.image.toLowerCase().includes(ite.toLowerCase());
                // item.type_title.includes(ite);
            })
            setData(filterResult);
        }
    }
    const changeIndexedOption = (ite) => {
        // console.log("typess", ite);
        setData(second);

        if (selectedIndexed === ite) {
            setSelectedIndexed('');
            setData(second);
        } else {
            setSelectedIndexed(ite);
            const filterResult = second.filter((item) => {
                return item.indexed.toLowerCase().includes(ite.toLowerCase());
                // item.type_title.includes(ite);
            })
            setData(filterResult);
        }
    }
    useEffect(() => {
        getPublication();
        getRegion();
        getGenres();
        getType();
    }, [])
    return (
        <>
            <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                    <span onClick={Reset} style={{ fontSize: 12, color: 'white', textDecoration: 'underline' }}>Reset</span>
                    <div className="gro">
                        <form>

                            <div className="form-group">
                                <label id='home-label'>Publication Name</label>
                                <input type="text" className="form-control" name='search' value={search} onChange={handleSearch} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Search Publication Name" />
                            </div>
                            <div className="form-group">
                                <label id='home-label'>Sort By</label>
                                <select name="sortOrder" value={sortOrder} id="" onChange={handleSortChange} className="form-control">
                                    <option value="p_asc">Price (Asc)</option>
                                    <option value="p_desc">Price (Desc)</option>
                                    {/* <option value="d_asc">Domain Authority(Asc)</option>
                                    <option value="d_desc">Domain Authority(Desc)</option> */}
                                </select>
                            </div>
                            {/* 
                            <div className="form-group">
                                <label id='home-label'>Price Range</label>
                                <div className='px-2'>

                                    <Slider


                                        min={minValue}
                                        max={maxValue}
                                        value={slideValue}
                                        onChange={handleChange}

                                    // getAriaValueText={valuetext}
                                    />
                                    <div className="flex justify-between">
                                        <span className='first'>{slideValue[0].toLocaleString()}</span>
                                        <span className='second'>{slideValue[1].toLocaleString()}</span>
                                    </div>

                                </div>

                            </div> */}
                            <div className="form-group">
                                <label id='home-label'>Select Region</label>
                                <select name="sortRegion" value={sortRegion} id="" onChange={handleRegionChange} className="form-control">
                                    <option value="">Select Region</option>
                                    {
                                        regionData.map((item, index) => (
                                            <option value={item.region_title}>{item.region_title}</option>

                                        ))
                                    }

                                </select>
                            </div>
                            <div className="form-group ">
                                <label id='home-label'>Select Genres</label>

                                <div className="flex flex-wrap">
                                    {Genres.map((item, index) => (
                                        <div className="custom-control custom-checkbox ">
                                            {/* <input type="radio" hidden className="custom-control-input" onChange={chooseGenre} name="genres" value={item.id} id={'genres' + item.id} /> */}

                                            <label className={selectedGenre == item.id ? "custom-control-label-select" : "custom-control-label"} onClick={() => { chooseGenreOption(item.id) }}>{item.genre_title}</label>
                                        </div>
                                    ))}
                                </div>


                            </div>

                            {/* <div className="form-group ">
                                <label id='home-label'>Type</label>

                                <div className="flex flex-wrap">
                                    {typeData.map((item, index) => (
                                        <div className="custom-control custom-checkbox ">
                                            <input type="radio" hidden className="custom-control-input" name="genres" id={'genres' + item.id} />
                                            <label className={selectedType === item.type_title ? "custom-control-label-select" : "custom-control-label"} onClick={() => changeTypeOption(item.type_title)} htmlFor={'type' + item.id}>{item.type_title}</label>
                                        </div>
                                    ))}
                                </div>


                            </div> */}
                            <div className="form-group ">
                                <label id='home-label'>Sponsored</label>

                                <div className="flex flex-wrap">
                                    {Sponsored.map((item, index) => (
                                        <div className="custom-control custom-checkbox ">
                                            <label className={selectedSpons === item.name ? "custom-control-label-select" : "custom-control-label"} onClick={() => changeSponsOption(item.name)} >{item.name}</label>
                                        </div>
                                    ))}
                                </div>


                            </div>
                            <div className="form-group ">
                                <label id='home-label'>Do Follow</label>

                                <div className="flex flex-wrap">
                                    {Follow.map((item, index) => (
                                        <div className="custom-control custom-checkbox ">
                                            {/* <input type="radio" hidden className="custom-control-input" name="follow" id={'follow' + item.id} /> */}
                                            <label className={selectedDo === item.name ? "custom-control-label-select" : "custom-control-label"} onClick={() => changeDoOption(item.name)}>{item.name}</label>
                                        </div>
                                    ))}
                                </div>


                            </div>
                            <div className="form-group ">
                                <label id='home-label'>Image</label>

                                <div className="flex flex-wrap">
                                    {Images.map((item, index) => (
                                        <div className="custom-control custom-checkbox ">
                                            {/* <input type="radio" hidden className="custom-control-input" name="follow" id={'follow' + item.id} /> */}
                                            <label className={selectedImage === item.name ? "custom-control-label-select" : "custom-control-label"} onClick={() => changeImagesOption(item.name)}>{item.name}</label>
                                        </div>
                                    ))}
                                </div>


                            </div>
                            <div className="form-group ">
                                <label id='home-label'>Indexed</label>

                                <div className="flex flex-wrap">
                                    {Indexed.map((item, index) => (
                                        <div className="custom-control custom-checkbox ">
                                            {/* <input type="radio" hidden className="custom-control-input" name="follow" id={'follow' + item.id} /> */}
                                            <label className={selectedIndexed === item.name ? "custom-control-label-select" : "custom-control-label"} onClick={() => changeIndexedOption(item.name)}>{item.name}</label>
                                        </div>
                                    ))}
                                </div>


                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-9">

                    <div className="table-responsive-lg" >
                        <Tooltip target=".tooltip-button1" position="top" autoHide={false}>
                            <div className="bg-black">
                                <h6 className='text-center text-white'>Domain Authority</h6>
                                <p className='bh'>Search engine ranking score(1 - 100)</p>
                            </div>
                        </Tooltip>
                        <Tooltip target=".tooltip-button2" position="top" autoHide={false}>
                            <div className="bg-black">
                                <h6 className='text-center text-white'>Time At Arrive</h6>
                                <p className='bh'>Estimated time of delivery</p>
                            </div>
                        </Tooltip>
                        <table className="table table-striped table-hover table-responsive-lg">
                            <thead>
                                <tr className='text-center'>
                                    <th style={{ minWidth: '200px' }}>Publication</th>
                                    <th>Genres</th>
                                    <th>Price</th>
                                    <th ><div className='flex align-items-center'><span>Da</span> <span className="tooltip-button1"><BiHelpCircle fontSize={16} /></span></div></th>
                                    <th ><div className='flex align-items-center'><span>TAT</span> <span className="tooltip-button2"><BiHelpCircle fontSize={16} /></span></div></th>
                                    <th>REGION</th>
                                    <th>SPONSORED</th>
                                    <th>INDEXED</th>
                                    <th>IMAGE</th>
                                    <th>DO FOLLOW</th>
                                    <th>EXAMPLE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.length > 0 ? data.filter((item) => {
                                        return search.toLowerCase() === "" ? item : item.publication_name.toLowerCase().includes(search.toLowerCase());
                                    }).map((item, index) => (
                                        <tr className='text-center' key={item.id} style={{ verticalAlign: 'center' }}>
                                            <td>
                                                <div className="flex items-center h-100">
                                                    <img className='company-img' src={IMAGE_URL + '/' + item.cover_image} alt="" />
                                                    <div>
                                                        <p className='company-name'><AutoHttpsLink href={item.publication_link} >{item.publication_name}</AutoHttpsLink></p>
                                                        {/* {item.type_title != "" ? (<li className={'typex'}>{item.type_title}</li>) : null} */}
                                                    </div>

                                                </div>
                                            </td>
                                            <td>
                                                {loopData(item.genre_id).length > 1 ?
                                                    <>
                                                        <div className='flex align-items-center'><span>{loopData(item.genre_id).length + ' Genres'}</span>  <span className={"tooltip-butto" + item.id + " ml-2"}><BiHelpCircle fontSize={13} /></span></div>
                                                        <Tooltip target={".tooltip-butto" + item.id} position="top" autoHide={false}>
                                                            <div className="flex align-items-center p-3" style={{ backgroundColor: 'white', border: '1px solid #bebebe' }} >
                                                                {loopData(item.genre_id).map((subtem, index) => (
                                                                    <li key={index} className='mx-2' style={{ background: 'whitesmoke', padding: 5, fontSize: 12, borderRadius: 8, listStyle: 'none' }}>{subtem.genre_title}</li>
                                                                ))}
                                                            </div>
                                                        </Tooltip>
                                                    </>

                                                    : <li key={index} className='mx-0' style={{ background: 'whitesmoke', padding: 5, fontSize: 12, borderRadius: 8, listStyle: 'none' }}>{loopData(item.genre_id).genre_title}</li>}



                                            </td>
                                            <td><CurrencyFormatter amount={'$' + item.price} /></td>
                                            <td>{item.da}</td>
                                            <td>{item.tat}</td>
                                            <td className='capitalize'>{item.region_title}</td>
                                            <td className='capitalize'>{item.sponsored}</td>
                                            <td className='capitalize'>{item.indexed}</td>
                                            <td className='capitalize'>{item.image}</td>
                                            <td className='capitalize'>{item.do_follow}</td>
                                            <td >
                                                {item.preview_image != null ? (<>  <a href={IMAGE_URL + '/' + item.preview_image} target='_blank' rel="noreferrer" className='text-center mx-auto'><AiOutlinePicture className={"text-center mx-auto tooltip-buttons" + item.id} fontSize={24} /></a>
                                                    <Tooltip target={".tooltip-buttons" + item.id} position="bottom" autoHide={false}>
                                                        <div lassName="flex align-items-center p-3" style={{ backgroundColor: 'white', border: '1px solid #bebebe', width: 200, minHeight: '250px', maxHeight: '250px', borderRadius: 10, opacity: 0.9, backdropFilter: 'blur(10px)', overflow: 'hidden' }} >
                                                            <p>Click to open full image</p>
                                                            <img src={IMAGE_URL + '/' + item.preview_image} height={'100%'} width={'100%'} alt="" />
                                                        </div>
                                                    </Tooltip> </>) : null}

                                            </td>

                                        </tr>
                                    ))
                                        : (
                                            <tr className='text-center'>

                                                {isLoading ? (<td colSpan={11} > <div className="w-100 flex justify-center"> <div className="loading-spinner"></div></div></td>) : (<td colSpan={11} >No Data found</td>)}

                                            </tr>
                                        )
                                }


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

const Group = () => {
    return (
        <div className="container-fluid  justify-center p-3 align-items-center">
            <div className="box justify-evenly gap-3 g-3 ">
                {/* <div className=""></div> */}
                <div className="item item1" style={{ borderLeft: '1px solid white', }}>
                    <div className="text-center">

                        <div className="flex justify-center">

                            <div className="">
                                <table id='custom-table1' >
                                    <thead>
                                        <th colSpan={4}>
                                            <h6 className="title">
                                                Verified Engagement Groups Packages
                                            </h6>
                                        </th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Verified Engagement Group</td>
                                            <td>$50</td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>Verified Engagement Groups</td>
                                            <td>$150</td>
                                        </tr>
                                        <tr>
                                            <td>10</td>
                                            <td>Verified Engagement Groups</td>
                                            <td>$250</td>
                                        </tr>
                                        <tr>
                                            <td>20</td>
                                            <td>Verified Engagement Groups</td>
                                            <td>$400</td>
                                        </tr>
                                        <tr>
                                            <td>50</td>
                                            <td>Verified Engagement Groups</td>
                                            <td>$800</td>
                                        </tr>
                                        <tr>
                                            <td>70</td>
                                            <td>Verified Engagement Groups</td>
                                            <td>$1,000</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <p className='text-white mt-4 text-center' style={{ fontSize: 10 }}>Each group it's carefully managed by our team, we remove leechers and inactive members also we add new axtive members on each group so the groups stay active all the time.</p>
                        <p className="mt-3 text-left" style={{ fontSize: 12, color: 'var(--primary-color)' }}>One time payment
                            <br /> Contacts us to purchase
                        </p>
                    </div>
                </div>
                {/* <div className=""></div> */}
                {/* <div className="item item2" style={{ borderLeft: '1px solid white' }}>
                    <div className="text-center">

                        <div className="flex justify-center">
                            <div className=""></div>
                            <div className="">
                                <table id='custom-table'>
                                    <thead>
                                        <th colSpan={4}>
                                            <h6 className="title">
                                                Verified Engagement Groups Packages
                                            </h6>
                                            <p className="titlep">(For Verified Accounts Only)</p>
                                        </th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Verified Engagement Group</td>
                                            <td>$30</td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>Verified Engagement Groups</td>
                                            <td>$100</td>
                                        </tr>
                                        <tr>
                                            <td>10</td>
                                            <td>Verified Engagement Groups</td>
                                            <td>$150</td>
                                        </tr>
                                        <tr>
                                            <td>20</td>
                                            <td>Verified Engagement Groups</td>
                                            <td>$250</td>
                                        </tr>
                                        <tr>
                                            <td>50</td>
                                            <td>Verified Engagement Groups</td>
                                            <td>$500</td>
                                        </tr>
                                        <tr>
                                            <td>70</td>
                                            <td>Verified Engagement Groups</td>
                                            <td>$600</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <p className='text-white mt-4 text-center' style={{ fontSize: 10 }}>Each group it's carefully managed by our team, we remove leechers and inactive members also we add new axtive members on each group so the groups stay active all the time.</p>
                        <p className="mt-3 text-center" style={{ fontSize: 12, color: 'var(--primary-color)' }}>One time payment <br /> Contacts us to purchase</p>
                    </div>
                </div> */}
                {/* <div className=""></div> */}

            </div>
            <div className=""></div>
            <hr />
            <div className="box justify-center my-3">
                <div className="item item3" style={{ borderLeft: '1px solid white' }}>
                    <div className="text-center">
                        <h6 className="title">
                            Verified Comments Delivery
                        </h6>
                        <div className="sm:flex lg:flex xs:inline-block lg:gap-4 justify-center">
                            <div className="">
                                <table id='custom-table'>
                                    <thead>
                                        <th colSpan={4}>

                                        </th>
                                        <tr className='text-center'>
                                            <th colSpan={4} className="title fw-lighter text-center" >Price for 1 post</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><li>10 - 10</li></td>
                                            <td>Comments</td>
                                            <td>-</td>
                                            <td className="title fw-light">$15</td>
                                        </tr>
                                        <tr>
                                            <td><li>10 - 20</li></td>
                                            <td>Comments</td>
                                            <td>-</td>
                                            <td className="title fw-light">$20</td>
                                        </tr>
                                        <tr>
                                            <td><li>20 - 30</li></td>
                                            <td>Comments</td>
                                            <td>-</td>
                                            <td className="title fw-light">$30</td>
                                        </tr>
                                        <tr>
                                            <td><li>30 - 40</li></td>
                                            <td>Comments</td>
                                            <td>-</td>
                                            <td className="title fw-light">$40</td>
                                        </tr>
                                        <tr>
                                            <td><li>40 - 50</li></td>
                                            <td>Comments</td>
                                            <td>-</td>
                                            <td className="title fw-light">$50</td>
                                        </tr>
                                        <tr>
                                            <td><li>50 - 60</li></td>
                                            <td>Comments</td>
                                            <td>-</td>
                                            <td className="title fw-light">$60</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div className="">
                                <table id='custom-table'>
                                    <thead>
                                        <tr className='text-center'>
                                            <th className="title fw-lighter text-center " colSpan={4}>Price for 10 posts</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><li>10 - 10</li></td>
                                            <td>Comments</td>
                                            <td>-</td>
                                            <td className="title fw-light">$100</td>
                                        </tr>
                                        <tr>
                                            <td><li>10 - 20</li></td>
                                            <td>Comments</td>
                                            <td>-</td>
                                            <td className="title fw-light">$150</td>
                                        </tr>
                                        <tr>
                                            <td><li>20 - 30</li></td>
                                            <td>Comments</td>
                                            <td>-</td>
                                            <td className="title fw-light">$200</td>
                                        </tr>
                                        <tr>
                                            <td><li>30 - 40</li></td>
                                            <td>Comments</td>
                                            <td>-</td>
                                            <td className="title fw-light">$300</td>
                                        </tr>
                                        <tr>
                                            <td><li>40 - 50</li></td>
                                            <td>Comments</td>
                                            <td>-</td>
                                            <td className="title fw-light">$400</td>
                                        </tr>
                                        <tr>
                                            <td><li>50 - 60</li></td>
                                            <td>Comments</td>
                                            <td>-</td>
                                            <td className="title fw-light">$500</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <p className='text-white mt-4 text-center' style={{ fontSize: 10 }}>The delivering time it's 24/Hrs Max <br /> The starting time 1/Hour</p>
                        <p className="mt-3 text-center" style={{ fontSize: 12, color: 'var(--primary-color)' }}>Contacts us to purchase</p>
                    </div>
                </div>


            </div>

            <div className="row my-4">
                <div className="col-12 col-md-12 flex justify-center col-lg-12" style={{ paddingLeft: 12 }}>
                    <div className="text-center">
                        <div className="myRow">
                            <div className="co" >
                                <div className="custom-card">
                                    <h5>SILVER</h5>\
                                    <div className="custom-box">
                                        <div className="b">
                                            <h5>10 Posts Monthly</h5>
                                            <p><li>5 - 10k Likes Each Post</li> <li>10 - 20k Views Each Post</li> </p>
                                        </div>

                                    </div>
                                    <h5>$100</h5>

                                </div>
                            </div>
                            <div className="co">
                                <div className="custom-card">
                                    <h5>Gold</h5>\
                                    <div className="custom-box">
                                        <div className="b">
                                            <h5>20 Posts Monthly</h5>
                                            <p><li>10 - 20k Likes Each Post</li> <li>30 - 50k Views Each Post</li> </p>
                                        </div>

                                    </div>
                                    <h5>$250</h5>
                                </div>
                            </div>
                            <div className="co">
                                <div className="custom-card">
                                    <h5>Platinum</h5>\
                                    <div className="custom-box">
                                        <div className="b">
                                            <h5>20 Posts Monthly</h5>
                                            <p><li>10 - 20k Likes Each Post</li> <li>30 - 50k Views Each Post</li> <li>20 - 30 Verified Comments</li> </p>
                                            <span className='d-block float-right'>For Each Post</span>
                                        </div>

                                    </div>
                                    <h5>$500</h5>
                                </div>
                            </div>
                        </div>


                        <p className='text-white mt-4 text-center' style={{ fontSize: 10 }}>When you purchase a monthly package it will be available for 31days.</p>
                    </div>
                </div>
            </div>


        </div>
    )

}

const Info = () => {
    return (
        <div className="container-fluid flex justify-center align-items-center">
            <div className="box ">
                <div className="item item1 flex" style={{ borderLeft: '1px solid white' }}>
                    <div className="text-center m-auto">

                        <div className="flex justify-center align-items-center">

                            <div className="">
                                <table id='custom-table2' >
                                    <thead>
                                        <th colSpan={4}>
                                            <h6 className="title">
                                                INSTAGRAM HQ FOLLOWERS (NON DROP)
                                            </h6>
                                        </th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><li>10k</li></td>
                                            <td>Followers</td>
                                            <td>-</td>
                                            <td>$40</td>
                                        </tr>
                                        <tr>
                                            <td><li>50k</li></td>
                                            <td>Followers</td>
                                            <td>-</td>
                                            <td>$150</td>
                                        </tr>
                                        <tr>
                                            <td><li>100k</li></td>
                                            <td>Followers</td>
                                            <td>-</td>
                                            <td>$250</td>
                                        </tr>
                                        <tr>
                                            <td><li>500k</li></td>
                                            <td>Followers</td>
                                            <td>-</td>
                                            <td>$1,000</td>
                                        </tr>
                                        <tr>
                                            <td><li>900k</li></td>
                                            <td>Followers</td>
                                            <td>-</td>
                                            <td>$1,500</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        </div>
                        {/* <p className='text-white mt-4 text-center' style={{ fontSize: 10 }}>The delivering time it's based on the amount of followers that you purchase. <br /> The starting time will be on minutes.</p> */}
                        <p className="mt-3 text-center" style={{ fontSize: 12, color: 'var(--primary-color)' }}>One time payment
                            {/* <br /> Contacts us to purchase */}
                        </p>
                    </div>
                </div>
                <div className="item item2 flex" style={{ borderLeft: '1px solid white' }}>
                    <div className="text-center m-auto">

                        <div className="flex justify-center">
                            <div className=""></div>
                            <div className="">
                                <table id='custom-table'>
                                    <thead>
                                        <th colSpan={4}>
                                            <h6 className="title">
                                                INSTAGRAM HQ VIEWS (NON DROP)
                                            </h6>
                                            {/* <p className="titlep">Price for 1 post</p> */}
                                        </th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><li>10k</li></td>
                                            <td>Views</td>
                                            <td>-</td>
                                            <td>$10</td>
                                        </tr>
                                        <tr>
                                            <td><li>50k</li></td>
                                            <td>Views</td>
                                            <td>-</td>
                                            <td>$20</td>
                                        </tr>
                                        <tr>
                                            <td><li>100k</li></td>
                                            <td>Views</td>
                                            <td>-</td>
                                            <td>$30</td>
                                        </tr>
                                        <tr>
                                            <td><li>500k</li></td>
                                            <td>Views</td>
                                            <td>-</td>
                                            <td>$80</td>
                                        </tr>
                                        <tr>
                                            <td><li>1M</li></td>
                                            <td>Views</td>
                                            <td>-</td>
                                            <td>$120</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        </div>
                        {/* <p className='text-white mt-4 text-center' style={{ fontSize: 10 }}>The delivering time it's based on the amount of views that you purchase. <br /> The starting time will be on minutes.</p> */}
                        <p className="mt-3 text-center" style={{ fontSize: 12, color: 'var(--primary-color)' }}>One time payment
                            {/* <br /> Contacts us to purchase */}
                        </p>
                    </div>
                </div>

                <div className="item item3" style={{ borderLeft: '1px solid white' }}>
                    <div className="text-center">
                        <h6 className="title">
                            INSTAGRAM HQ LIKES (NON DROP)
                        </h6>
                        <div className="sm:flex lg:flex xs:inline-block lg:gap-4 justify-center">
                            <div className="">
                                <table id='custom-table'>
                                    <thead>
                                        <th colSpan={4}>

                                        </th>
                                        {/* <tr >
                                            <th colSpan={4} className="title fw-bold text-center" style={{ fontSize: 10 }} >Price for 1 post</th>

                                        </tr> */}
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><li>5K</li></td>
                                            <td>Likes</td>
                                            <td> - </td>
                                            <td className="title fw-light">$5</td>
                                        </tr>
                                        <tr>
                                            <td><li>10K</li></td>
                                            <td>Likes</td>
                                            <td> - </td>
                                            <td className="title fw-light">$10</td>
                                        </tr>
                                        <tr>
                                            <td><li>20K</li></td>
                                            <td>Likes</td>
                                            <td> - </td>
                                            <td className="title fw-light">$15</td>
                                        </tr>
                                        <tr>
                                            <td><li>40K</li></td>
                                            <td>Likes</td>
                                            <td> - </td>
                                            <td className="title fw-light">$25</td>
                                        </tr>
                                        <tr>
                                            <td><li>80k</li></td>
                                            <td>Likes</td>
                                            <td> - </td>
                                            <td className="title fw-light">$40</td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>
                            {/* <div className="">
                                <table id='custom-table'>
                                    <thead>
                                        <tr >
                                            <th colSpan={4} className="title fw-bold text-center" style={{ fontSize: 10 }} >Price for  post</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><li>5k</li></td>
                                            <td>Likes</td>
                                            <td> - </td>
                                            <td className="title fw-light">$30</td>
                                        </tr>
                                        <tr>
                                            <td><li>10k</li></td>
                                            <td>Likes</td>
                                            <td> - </td>
                                            <td className="title fw-light">$50</td>
                                        </tr>
                                        <tr>
                                            <td><li>20k</li></td>
                                            <td>Likes</td>
                                            <td> - </td>
                                            <td className="title fw-light">$90</td>
                                        </tr>
                                        <tr>
                                            <td><li>40k</li></td>
                                            <td>Likes</td>
                                            <td> - </td>
                                            <td className="title fw-light">$150</td>
                                        </tr>
                                        <tr>
                                            <td><li>80k</li></td>
                                            <td>Likes</td>
                                            <td> - </td>
                                            <td className="title fw-light">$250</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div> */}

                        </div>

                        {/* <p className='text-white mt-4 text-center' style={{ fontSize: 10 }}>The delivering time it's based on the amount of likes that you purchase. <br /> The starting time will be on minutes</p>
                        <p className="mt-3 text-center" style={{ fontSize: 12, color: 'var(--primary-color)' }}>Contacts us to purchase</p> */}
                    </div>
                </div>



                <div className="item item4" style={{ borderLeft: '1px solid white' }}>
                    <div className="text-center">

                        <div className="flex justify-center">

                            <div className="">
                                <table id='custom-table2' >
                                    <thead>
                                        <th colSpan={4}>
                                            <h6 className="title">
                                                SPOTIFY HQ FREE PLAYS (NON DROP)
                                            </h6>
                                        </th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><li>10k</li></td>
                                            <td>Plays</td>
                                            <td>-</td>
                                            <td>$20</td>
                                        </tr>
                                        <tr>
                                            <td><li>50k</li></td>
                                            <td>Plays</td>
                                            <td>-</td>
                                            <td>$80</td>
                                        </tr>
                                        <tr>
                                            <td><li>100k</li></td>
                                            <td>Plays</td>
                                            <td>-</td>
                                            <td>$120</td>
                                        </tr>
                                        <tr>
                                            <td><li>500k</li></td>
                                            <td>Plays</td>
                                            <td>-</td>
                                            <td>$300</td>
                                        </tr>
                                        <tr>
                                            <td><li>900k</li></td>
                                            <td>Plays</td>
                                            <td>-</td>
                                            <td>$500</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        </div>
                        {/* <p className='text-white mt-4 text-left' style={{ fontSize: 10 }}>The delivering time it's based on the amount of plays that you purchase. <br /> The starting time will be on 0-6 Hours.</p> */}
                        <p className="mt-3 text-left" style={{ fontSize: 12, color: 'var(--primary-color)' }}>One time payment
                            {/* <br /> Contacts us to purchase */}
                        </p>
                    </div>
                </div>
                <div className="item item4" style={{ borderLeft: '1px solid white' }}>
                    <div className="text-center">

                        <div className="flex justify-center">
                            <div className=""></div>
                            <div className="">
                                <table id='custom-table'>
                                    <thead>
                                        <th colSpan={4}>
                                            <h6 className="title">
                                                SPOTIFY HQ USA MONTHLY LISTENERS
                                            </h6>
                                            {/* <p className="titlep">Price for 1 post</p> */}
                                        </th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><li>10k</li></td>
                                            <td>Listeners</td>
                                            <td>-</td>
                                            <td>$100</td>
                                        </tr>
                                        <tr>
                                            <td><li>50k</li></td>
                                            <td>Listeners</td>
                                            <td>-</td>
                                            <td>$300</td>
                                        </tr>
                                        <tr>
                                            <td><li>100k</li></td>
                                            <td>Listeners</td>
                                            <td>-</td>
                                            <td>$500</td>
                                        </tr>
                                        <tr>
                                            <td><li>500k</li></td>
                                            <td>Listeners</td>
                                            <td>-</td>
                                            <td>$2,000</td>
                                        </tr>
                                        <tr>
                                            <td><li>1M</li></td>
                                            <td>Listeners</td>
                                            <td>-</td>
                                            <td>$3,500</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        </div>
                        {/* <p className='text-white mt-4 text-left' style={{ fontSize: 10 }}>The delivering time it's based on the amount of plays that you purchase. <br /> The starting time will be on minutes.</p> */}
                        <p className="mt-3 text-left" style={{ fontSize: 12, color: 'var(--primary-color)' }}>One time payment
                            {/* <br /> Contacts us to purchase */}
                        </p>
                    </div>
                </div>


                {/* Youtube */}
                <div className="item item4" style={{ borderLeft: '1px solid white' }}>
                    <div className="text-center">

                        <div className="flex justify-center">

                            <div className="">
                                <table id='custom-table2' >
                                    <thead>
                                        <th colSpan={4}>
                                            <h6 className="title">
                                                YOUTUBE HQ MIXED VIEWS
                                            </h6>
                                        </th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><li>10k</li></td>
                                            <td>Views</td>
                                            <td>-</td>
                                            <td>$90</td>
                                        </tr>
                                        <tr>
                                            <td><li>50k</li></td>
                                            <td>Views</td>
                                            <td>-</td>
                                            <td>$250</td>
                                        </tr>
                                        <tr>
                                            <td><li>100k</li></td>
                                            <td>Views</td>
                                            <td>-</td>
                                            <td>$450</td>
                                        </tr>
                                        <tr>
                                            <td><li>500k</li></td>
                                            <td>Views</td>
                                            <td>-</td>
                                            <td>$1,800</td>
                                        </tr>
                                        <tr>
                                            <td><li>900k</li></td>
                                            <td>Views</td>
                                            <td>-</td>
                                            <td>$2,800</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        </div>
                        {/* <p className='text-white mt-4 text-left' style={{ fontSize: 10 }}>The delivering time it's based on the amount of views that you purchase. <br /> The starting time will be on 0-2 Hours.</p> */}
                        <p className="mt-3 text-center" style={{ fontSize: 12, color: 'var(--primary-color)' }}>One time payment
                            {/* <br /> Contacts us to purchase */}
                        </p>
                    </div>
                </div>
                <div className="item item4" style={{ borderLeft: '1px solid white' }}>
                    <div className="text-center">

                        <div className="flex justify-center">
                            <div className=""></div>
                            <div className="">
                                <table id='custom-table'>
                                    <thead>
                                        <th colSpan={4}>
                                            <h6 className="title">
                                                YOUTUBE HQ USA LIKES
                                            </h6>
                                            {/* <p className="titlep">Price for 1 post</p> */}
                                        </th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><li>1k</li></td>
                                            <td>Likes</td>
                                            <td>-</td>
                                            <td>$25</td>
                                        </tr>
                                        <tr>
                                            <td><li>5k</li></td>
                                            <td>Likes</td>
                                            <td>-</td>
                                            <td>$100</td>
                                        </tr>
                                        <tr>
                                            <td><li>10k</li></td>
                                            <td>Likes</td>
                                            <td>-</td>
                                            <td>$150</td>
                                        </tr>
                                        <tr>
                                            <td><li>20k</li></td>
                                            <td>Likes</td>
                                            <td>-</td>
                                            <td>$250</td>
                                        </tr>
                                        <tr>
                                            <td><li>50k</li></td>
                                            <td>Likes</td>
                                            <td>-</td>
                                            <td>$500</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        </div>
                        {/* <p className='text-white mt-4 text-left' style={{ fontSize: 10 }}>The delivering time it's based on the amount of likes that you purchase. <br /> The starting time will be on 0-1 Hour.</p> */}
                        <p className="mt-3 text-center" style={{ fontSize: 12, color: 'var(--primary-color)' }}>One time payment
                            {/* <br /> Contacts us to purchase */}
                        </p>
                    </div>
                </div>
                <div className="item item4" style={{ borderLeft: '1px solid white' }}>
                    <div className="text-center">

                        <div className="flex justify-center">
                            <div className=""></div>
                            <div className="">
                                <table id='custom-table'>
                                    <thead>
                                        <th colSpan={4}>
                                            <h6 className="title">
                                                YOUTUBE HQ SUBSCRIBERS
                                            </h6>
                                            {/* <p className="titlep">Price for 1 post</p> */}
                                        </th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><li>1k</li></td>
                                            <td>Subscribers</td>
                                            <td>-</td>
                                            <td>$60</td>
                                        </tr>
                                        <tr>
                                            <td><li>5k</li></td>
                                            <td>Subscribers</td>
                                            <td>-</td>
                                            <td>$200</td>
                                        </tr>
                                        <tr>
                                            <td><li>20k</li></td>
                                            <td>Subscribers</td>
                                            <td>-</td>
                                            <td>$600</td>
                                        </tr>
                                        <tr>
                                            <td><li>50k</li></td>
                                            <td>Subscribers</td>
                                            <td>-</td>
                                            <td>$1,200</td>
                                        </tr>
                                        <tr>
                                            <td><li>100k</li></td>
                                            <td>Subscribers</td>
                                            <td>-</td>
                                            <td>$2,000</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        </div>
                        {/* <p className='text-white mt-4 text-left' style={{ fontSize: 10 }}>The delivering time it's based on the amount of subscribers that you purchase. <br /> The starting time will be on 0-1 Hour.</p> */}
                        <p className="mt-3 text-center" style={{ fontSize: 12, color: 'var(--primary-color)' }}>One time payment
                            {/* <br /> Contacts us to purchase */}
                        </p>
                    </div>
                </div>


            </div>


        </div>
    )

}

const Other = () => {
    return (
        <>
            <div className="container-fluid flex justify-center align-items-center  myBg" style={{ minHeight: '30vh' }}>

                <div className="box">
                    <div className="item item1 text-center">
                        <h2>Billboard Exposure in times Square</h2>
                        <p>Get in touch today to get you or your business showcased on Major BillBoards right in the heart of New York City (Duffy Square).
                        </p>
                    
                        <p className="bottom">
                            (We will also do a live video for you)
                        </p>
                        <p className="bottom">
                            <b><em>Price</em> - $500</b>
                        </p>
                    </div>
                    <div className="item item2 text-center">
                        <h2>TikTok Verification </h2>
                        <p>3- 4 Articles published on last 2months</p>

                        {/* <p className="bottom">
                            <b><em>Price</em> $2,500+ Tat 7 - 21 Days Max</b>
                        </p> */}
                            <p className="bottom">
                            <b><em>Price</em> $2,500+
                                {/* Tat 7 - 21 Days Max */}
                            </b>
                        </p>
                    </div>
                    <div className="item item3 text-center">
                        <h2>YouTube Verification </h2>
                        <p>No requirements. Works on everyone even with 0 Subs.</p>

                        {/* <p className="bottom">
                            <b><em>Price</em> $900 Tat 24/hrs Max</b>
                        </p> */}
                             <p className="bottom">
                            <b><em>Price</em> $900
                                {/* Tat 24/hrs Max */}
                            </b>
                        </p>
                    </div>
                    <div className="item item4 text-center">
                        <h2>Non Generic TikTok username claims</h2>
                        <p>Can do any to almost all</p>
                        <p>Must be inactive and 6L+</p>
                        <p className="bottom">
                            <b><em>Price</em> $2,000</b>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}